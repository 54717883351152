import React from 'react';

function Question({ question, answer, setAnswer, observation, setObservacion }) {

    const opcionSeleccionada = question.options.find(option => option.code === answer);

    return (
        <div className="my-3 p-3 border rounded">
            <div className="question-container">
                <span className="question-text">{question.text}</span>
                <div className="button-group">
                    {question.options.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => setAnswer(option.code)}
                            className={`btn ${answer === option.code ? 'btn-custom-success' : 'btn-custom-outline-primary'}`}
                        >
                            {option.text}
                        </button>
                    ))}
                </div>
            </div>

            {opcionSeleccionada?.aplicaObservacion && (
                <div className="form-group mt-3">
                    <textarea
                        id={`observacion-${question.id}`}
                        className="form-control"
                        value={observation}
                        onChange={(e) => setObservacion(e.target.value)}
                        placeholder="Observación"
                    ></textarea>
                </div>
            )}
        </div>
    );
}

export default Question;
