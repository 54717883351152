import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../auth/context/AuthContext';
import logo from './logosafite2.png';
import { Dropdown } from 'react-bootstrap';

const Navbar = ({ show }) => {
	const { logout } = useAuth();
	const location = useLocation();
	const [activeLink, setActiveLink] = useState(null);

	useEffect(() => {
		setActiveLink(location.pathname);
	}, [location]);

	return (
		<div className={show ? 'sidenav active' : 'sidenav'}>
			<div className='logo-container'>
				<img src={logo} alt="logo" className='logo' />
			</div>
			<div className="nav flex-column nav-pills">
				<Link className={`nav-link ${activeLink === '/' ? 'active' : ''}`} to="/">
					<span className="material-icons">home</span><strong> Inicio</strong>
				</Link>

				<Link className={`nav-link ${activeLink === '/usuarios' ? 'active' : ''}`} to="/usuarios">
					<span className="material-icons">person</span><strong> Usuarios</strong>
				</Link>

				{/* Dropdown para Empresas 
				<Link className={`nav-link dropdown-toggle ${openDropdown === 'empresas' ? 'active' : ''}`} onClick={() => toggleDropdown('empresas')}>
					<span className="material-icons">apartment</span><strong> Empresas</strong>
				</Link>
				{openDropdown === 'empresas' && (
					<div className="dropdown-menu show">
						<Link className={`dropdown-item ${activeLink === '/empresas' ? 'active' : ''}`} to="/empresas">
							<strong> Empresas</strong>
						</Link>

						<Link className={`dropdown-item ${activeLink === '/host' ? 'active' : ''}`} to="/host">
							<strong> Host</strong>
						</Link>
					</div>
				)}*/}
				
				{/* Dropdown para Encuestas
				<Link className={`nav-link dropdown-toggle ${openDropdown === 'encuestas' ? 'active' : ''}`} onClick={() => toggleDropdown('encuestas')}>
					<span className="material-icons">format_list_bulleted</span><strong> Encuestas</strong>
				</Link>
				{openDropdown === 'encuestas' && (
					<div className="dropdown-menu show">
						<Link className={`dropdown-item ${activeLink === '/encuestas' ? 'active' : ''}`} to="/encuestas">
							<strong>Encuestas</strong>
						</Link>
						<Link className={`dropdown-item ${activeLink === '/temas' ? 'active' : ''}`} to="/temas">
							<strong>Temas</strong>
						</Link>
						<Link className={`dropdown-item ${activeLink === '/tipo-pregunta' ? 'active' : ''}`} to="/tipo-pregunta">
							<strong>Tipos Pregunta</strong>
						</Link>
						<Link className={`dropdown-item ${activeLink === '/tipo-dato' ? 'active' : ''}`} to="/tipo-dato">
							<strong>Tipos Dato</strong>
						</Link>
					</div>
				)} */}

				<Dropdown>
					<Dropdown.Toggle id="dropdown-empresas" className={`nav-link ${activeLink === 'empresas' ? 'active' : ''}`}>
						<span className="material-icons">apartment</span><strong> Empresas</strong>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item>
							<Link className={`${activeLink === '/empresas' ? 'active' : ''}`} to="/empresas">
								<strong> Empresas</strong>
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link className={`${activeLink === '/host' ? 'active' : ''}`} to="/host">
								<strong> Host</strong>
							</Link>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<Dropdown>
					<Dropdown.Toggle id="dropdown-encuestas" className={`nav-link ${activeLink === 'encuestas' ? 'active' : ''}`}>
						<span className="material-icons">format_list_bulleted</span><strong> Encuestas</strong>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item>
							<Link className={`${activeLink === '/encuestas' ? 'active' : ''}`} to="/encuestas">
								<strong>Encuestas</strong>
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link className={`${activeLink === '/temas' ? 'active' : ''}`} to="/temas">
								<strong>Temas</strong>
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link className={`${activeLink === '/tipo-pregunta' ? 'active' : ''}`} to="/tipo-pregunta">
								<strong>Tipos Pregunta</strong>
							</Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Link className={`${activeLink === '/tipo-dato' ? 'active' : ''}`} to="/tipo-dato">
								<strong>Tipos Dato</strong>
							</Link>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<button className="btn btn-danger logout-button" onClick={logout}>
					<span className="material-icons">power_settings_new</span><strong> Cerrar Sesión</strong>
				</button>
			</div>
		</div>
	);
};

export default Navbar;
