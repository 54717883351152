import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import Loading from '../../components/globales/Loading';
import Title from '../../components/globales/Title';
import EmpresasTabla from './EmpresasTabla';

function Empresas() {

	const [empresasInicial, setEmpresasInicial] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

 	useEffect(() => {
		axios.get('/empresas/listar')
			.then(response => {
				setEmpresasInicial(response.data.data);
				setLoading(false);
			})
			.catch(error => {
				setError(error);
				setLoading(false);
			});
	}, []);
	
	if (loading) {
		return <div><Loading mensaje={'Cargando Empresas...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<>			
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<Title title={'Empresas'}/>
							<EmpresasTabla empresasInicial={empresasInicial} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Empresas;
