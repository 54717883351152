import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import Loading from '../../../../components/globales/Loading';
import { Table, Button, Modal, Form, Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Title from '../../../../components/globales/Title';

const RespuestasTabla = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [errors, setErrors] = useState([]);

	const [respuestas, setRespuestas] = useState([]);
	const [datoRespuesta, setDatoRespuesta] = useState([]);
	const [accion, setAccion] = useState('crear');

	const [nombre, setNombre] = useState('');
	const [verdadera, setVerdadera] = useState(0);
	const [aplicaObservacion, setAplicaObservacion] = useState(0);
	const [datoRespuestaSeleccionado, setDatoRespuestaSeleccionado] = useState('');
	const [idRespuesta, setIdRespuesta] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/encuestaPregunta/editar/${id}`);
				
				setRespuestas(response.data.respuestas);
				setDatoRespuesta(response.data.datoRespuesta);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError(error);
			}
		};

		fetchData();
	}, [id]);

	if (loading) {
		return <div><Loading mensaje={'Cargando Respuestas...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	const enviarRespuesta = (e) => {
		e.preventDefault();

		const respuesta = {
			nombre,			
			verdadera,
			pregunta: id,
			datoRespuesta: datoRespuestaSeleccionado,
			aplica_observacion: aplicaObservacion
		};

		let formErrors = {};

		if (!nombre) {
			formErrors.nombre = 'El nombre es obligatorio';
		}
		
		if (!datoRespuestaSeleccionado) {
			formErrors.datoRespuesta = 'Seleccione un dato de respuesta';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				
				axios.post('/encuestaRespuesta/crear', respuesta)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creada",
							text: "La respuesta ha sido creada con éxito",
							icon: "success"
						});

						setNombre('');
						setVerdadera(0);
						setIdRespuesta('');
						setDatoRespuestaSeleccionado('');
						setAplicaObservacion(0);
						setErrors([]);

						return axios.get(`/encuestaPregunta/editar/${id}`);
					})
					.then(response => {
						
						setRespuestas(response.data.respuestas);
						setDatoRespuesta(response.data.datoRespuesta);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {			
				axios.put(`/encuestaRespuesta/actualizar/${idRespuesta}`, respuesta)
					.then(response => {
						handleClose();

						Swal.fire({
							title: "Actualizada",
							text: "La respuesta sido actualizada con éxito",
							icon: "success"
						});

						setNombre('');
						setVerdadera(0);
						setIdRespuesta('');
						setDatoRespuestaSeleccionado('');
						setAplicaObservacion(0);
						setErrors([]);

						return axios.get(`/encuestaPregunta/editar/${id}`);
					})
					.then(response => {
						setRespuestas(response.data.respuestas);
						setDatoRespuesta(response.data.datoRespuesta);

					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const eliminarRespuesta = (id) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar este tema?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/encuestaRespuesta/borrar/${id}`)
					.then(response => {
						Swal.fire({
							title: "Borrada",
							text: "La respuesta ha sido eliminada con éxito",
							icon: "success"
						}).then(() => {
							setRespuestas(respuestas.filter(respuesta => respuesta.id !== id));
						});
					})
					.catch(error => {
						console.error('Error borrando la respuesta:', error);
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al intentar eliminar la respuesta.",
							icon: "error"
						});
					});
			}
		});
	};

	const abrirModal = (accion, respuesta = {}) => {
		setAccion(accion);
		if (accion === 'editar') {
			setIdRespuesta(respuesta.id);
			setNombre(respuesta.nombre);
			setVerdadera(respuesta.verdadera > 0 ? 1 : 0);
			setDatoRespuestaSeleccionado(respuesta.id_tipo_dato);
			setAplicaObservacion(respuesta.aplica_observacion > 0 ? 1 : 0);
			
		} else {
			setIdRespuesta('');
			setNombre('');
			setVerdadera(0);
			setDatoRespuestaSeleccionado('');
			setAplicaObservacion(0);

		}
		handleShow();
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = respuestas.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Respuestas'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No</th>
								<th width="40%;">Nombre</th>
								<th width="20%;">Verdadero (opcional)</th>
								<th width="10%;">Tipo Dato</th>
								<th width="10%;">Aplica Observación</th>
								<th width="10%;" style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
											<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.length > 0 ? (
								currentItems.map((respuesta, index) => (
									<tr key={index}>
										<td style={{ textAlign: 'center' }}>{indexOfFirstItem + index + 1}</td>
										<td>{respuesta.nombre}</td>
										<td>
											{respuesta.verdadera ? 'verdadero' : ''}
										</td>
										<td>{respuesta.nombre_tipo_respuesta}</td>
										<td>
											{respuesta.aplica_observacion ? 'Si' : 'No'}
										</td>
										<td style={{ textAlign: 'center' }}>
											<div className="btn-group" role="group">
												<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', respuesta)}>
													<span className="material-icons">edit</span>											
												</Button>
												<Button variant="outline-danger" title="Borrar" onClick={() => eliminarRespuesta(respuesta.id)} disabled={respuesta.registros_encuestas > 0}>
													<span className="material-icons">delete</span>
												</Button>
											</div>
										</td>
									</tr>
								))
								) : (
								<tr>
									<td colSpan="6" style={{ textAlign: 'center' }}>No hay respuestas</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(respuestas.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Respuesta'}
						{accion === 'editar' && 'Editar Respuesta'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>						
						<Form.Group className="mb-3" controlId="nombreInput">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="nombre"
								placeholder="Nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
								required
							/>
							{errors.nombre && <div className="alert alert-danger">{errors.nombre}</div>}
						</Form.Group>
						<Form.Group className="mb-3" controlId="verdaderaCheckbox">
							<Form.Check 
								type="checkbox"
								name="verdadera"
								label="Verdadera"
								checked={verdadera === 1}
								onChange={(e) => setVerdadera(e.target.checked ? 1 : 0)}
							/>
							{errors.verdadera && <div className="alert alert-danger">{errors.verdadera}</div>}
						</Form.Group>
						<Form.Group className="mb-3" controlId="datoRespuestaSelect">
							<Form.Label>Dato Respuesta</Form.Label>
							<Form.Control 
								as="select"
								name="datoRespuesta"
								value={datoRespuestaSeleccionado}
								onChange={(e) => setDatoRespuestaSeleccionado(e.target.value)}
								required
							>
								<option value="">Seleccione dato respuesta...</option>
								{Array.isArray(datoRespuesta) && datoRespuesta.length > 0 && datoRespuesta.map((dato) => (
									<option key={dato.id} value={dato.id}>
										{dato.nombre}
									</option>
								))}
							</Form.Control>
							{errors.datoRespuesta && <div className="alert alert-danger">{errors.datoRespuesta}</div>}
						</Form.Group>
						<Form.Group className="mb-3" controlId="aplicaObservacionCheckbox">
							<Form.Check 
								type="checkbox"
								name="aplicaObservacion"
								label="Aplica Observación"
								checked={aplicaObservacion === 1}
								onChange={(e) => setAplicaObservacion(e.target.checked ? 1 : 0)}
							/>
							{errors.aplica_observacion && <div className="alert alert-danger">{errors.aplica_observacion}</div>}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					<Button variant="primary" onClick={enviarRespuesta}>
						{accion === 'crear' ? 'Guardar' : 'Actualizar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RespuestasTabla;
