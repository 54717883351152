import React from 'react';

function Loading({ mensaje }) {
	return (
		<div className="loading-container">
			<div className="spinner"></div>
			<p className="loading-message">{mensaje}</p>
		</div>
	);
}

export default Loading;
