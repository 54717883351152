import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../axiosConfig';
import Loading from '../../../components/globales/Loading';
import { Table, Button, Modal, Form, Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Title from '../../../components/globales/Title';


export const EmpresasCodigo = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [errors, setErrors] = useState([]);
	const [codigo, setCodigo] = useState('');
	const [idCodigo, setIdCodigo] = useState('');
	const [codigos, setCodigos] = useState('');
	const [accion, setAccion] = useState('crear');


	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/empresas/listarCodigos/${id}`);
				
				setCodigos(response.data);
				
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError(error);
			}
		};

		fetchData();
	}, [id]);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	if (loading) {
		return <div><Loading mensaje={'Cargando Codigos...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	const enviarCodigo = (e) => {
		e.preventDefault();

		const codigoCrear = {
			id_empresa: id,
			codigo
		};

		let formErrors = {};

		if (!codigo) {
			formErrors.codigo = 'El codigo es obligatorio';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				axios.post('/sucursalEmpresa/crear', codigoCrear)				
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creado",
							text: "El código ha sido creado con éxito",
							icon: "success"
						});

						setCodigo('');
						setErrors([]);

						return axios.get(`/empresas/listarCodigos/${id}`);
					})
					.then(response => {
						const nuevosCodigos = response.data;
						
						setCodigos(nuevosCodigos);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {
				axios.put(`/sucursalEmpresa/actualizar/${idCodigo}`, codigoCrear)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Actualizado",
							text: "El código sido actualizado con éxito",
							icon: "success"
						});

						setCodigo('');
						setErrors([]);

						return axios.get(`/empresas/listarCodigos/${id}`);
					})
					.then(response => {
						const nuevosCodigos = response.data;
						setCodigos(nuevosCodigos);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const abrirModal = (accion, codigo = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {
			setIdCodigo(codigo.codigo_empresa);
			setCodigo(codigo.codigo);
		} else {
			setIdCodigo('');
			setCodigo('');
		}
		handleShow();
	};

	const eliminarCodigo = (codigo_empresa) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar la encuesta?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/sucursalEmpresa/borrar/${codigo_empresa}`)
					.then(response => {
						Swal.fire({
							title: "Borrada",
							text: "la encuesta ha sido eliminado con éxito",
							icon: "success"
						}).then(() => {
							setCodigos(codigos.filter(codigo => codigo.codigo_empresa !== codigo_empresa));
						});
					})
					.catch(error => {
						console.error('Error borrando el tema:', error);
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al intentar eliminar la encuesta.",
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = codigos.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	
	return (
		<div>
			<div className="table-responsive">
				<Table striped>
					<thead>
						<tr>
							<th style={{ textAlign: 'center' }}>No</th>
							<th>Código</th>
							<th style={{ textAlign: 'center' }}>
								<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
										<span className="material-icons">note_add</span>								
								</Button>
							</th>
						</tr>
					</thead>
					<tbody>
						{currentItems && currentItems.length > 0 ? (
							currentItems.map((item, index) => (
							<tr key={item.codigo}>
								<td style={{ textAlign: 'center' }}>{index + 1}</td>
								<td>{item.codigo}</td>								
								<td style={{ textAlign: 'center' }}>
									<div className="btn-group" role="group">
										<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', item)}>
											<span className="material-icons">edit</span>											
										</Button>
										<Button variant="outline-danger" title="Borrar" onClick={() => eliminarCodigo(item.codigo_empresa)} disabled={item.registros_preguntas > 0}>
											<span className="material-icons">delete</span>
										</Button>
									</div>
								</td>
							</tr>
							))
						) : (
							<tr>
								<td colSpan="4" style={{ textAlign: 'center' }}>
									No se ha encontrado información.
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				
				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(codigos.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Codigo'}
						{accion === 'editar' && 'Editar Codigo'}
						{accion === 'consultar' && 'Consultar Codigo'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Codigo</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="codigo"
								placeholder="Codigo"
								value={codigo}
								onChange={(e) => setCodigo(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.codigo &&
								<div className="alert alert-danger">
									{errors.codigo}
								</div>
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarCodigo}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	)
}
