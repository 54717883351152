import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import UsuariosTabla from './UsuariosTabla';
import Loading from '../../components/globales/Loading';

function Usuarios() {
	const [usuariosInicial, setUsuariosInicial] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

 	useEffect(() => {
		axios.get('/usuarios/listar')
			.then(response => {				
				
				setUsuariosInicial(response.data.users.data);
				setLoading(false);
			})
			.catch(error => {
				setError(error);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div><Loading mensaje={'Cargando Usuarios...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<UsuariosTabla usuariosInicial={usuariosInicial} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Usuarios;