import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton} from 'react-bootstrap';

function Sidebar() {
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/login');
	};
	const location = useLocation();
	const [activeLink, setActiveLink] = useState(null);

	useEffect(() => {
		const pathname = location.pathname;
		setActiveLink(pathname);
	}, [location]);

	return (
		<div>
			<div className="nav flex-column nav-pills sidebar" id="v-pills-tab" role="tablist" aria-orientation="vertical">
				<a className={`nav-link ${activeLink === '/usuarios' ? 'active' : ''}`} href="/usuarios">
					<span className="material-icons">person</span><strong> Usuarios</strong>
				</a>
				<a className={`nav-link ${activeLink === '/empresas' ? 'active' : ''}`} href="/empresas">
					<span className="material-icons">apartment</span><strong> Empresas</strong>
				</a>
				{/* <a className={`nav-link ${activeLink === '/respuestas' ? 'active' : ''}`} href="/respuestas">
					<span className="material-icons">check_box</span> Respuestas
				</a> */}
					<a className={'nav-link dropdown-toggle'} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="/encuestas">
						<span className="material-icons">format_list_bulleted</span><strong> Encuestas</strong>
					</a>
  				<div className="dropdown-menu">
					<a className={`dropdown-item ${activeLink === '/encuestas' ? 'active' : ''}`} href="/encuestas">
						<span className="material-icons"></span><strong> Encuestas</strong>
					</a>
					<a className={`dropdown-item ${activeLink === '/temas' ? 'active' : ''}`} href="/temas">
						<span className="material-icons"></span><strong> Temas</strong>
					</a>
					<a className={`dropdown-item ${activeLink === '/tipo-pregunta' ? 'active' : ''}`} href="/tipo-pregunta">
						<span className="material-icons"></span><strong> Tipos Pregunta</strong>
					</a>
					<a className={`dropdown-item ${activeLink === '/tipo-dato' ? 'active' : ''}`} href="/tipo-dato">
						<span className="material-icons"></span><strong> Tipos Dato</strong>
					</a>
				</div>
				<a className={'nav-link btn btn-outline-primary'} onClick={handleLogout}>
					<span className="material-icons">power_settings_new</span><strong> Cerrar Sesión</strong>
				</a>
			</div>

			{/* <DropdownButton
				title="Encuestas"
				variant="link"
				id="secondary"
			>
				<Dropdown.Item href="/encuestas" className={`nav-link ${activeLink === '/encuestas' ? 'active' : ''}`}>
					<span className="material-icons">format_list_bulleted</span> Encuestas
				</Dropdown.Item>
				<Dropdown.Item href="/temas" className={`nav-link ${activeLink === '/temas' ? 'active' : ''}`}>
					<span className="material-icons">library_books</span> Temas
				</Dropdown.Item>
				<Dropdown.Item href="/tipo-pregunta" className={`nav-link ${activeLink === '/tipo-pregunta' ? 'active' : ''}`}>
					<span className="material-icons">quiz</span> Tipos Pregunta
				</Dropdown.Item>
				<Dropdown.Item href="/tipo-dato" className={`nav-link ${activeLink === '/tipo-dato' ? 'active' : ''}`}>
					<span className="material-icons">edit_note</span> Tipos Dato
				</Dropdown.Item>
			</DropdownButton> */}

			<div className="nav flex-row nav-pills bottom-nav" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
				<a className={`nav-link ${activeLink === '/encuestas' ? 'active' : ''}`} href="/encuestas">Encuestas</a>
				<a className={`nav-link ${activeLink === '/temas' ? 'active' : ''}`} href="/temas">Temas</a>
				<a className={`nav-link ${activeLink === '/tipo-pregunta' ? 'active' : ''}`} href="/tipo-pregunta">Preguntas</a>
				<a className={`nav-link ${activeLink === '/tipo-dato' ? 'active' : ''}`} href="/tipo-dato">Datos</a>
			</div>
		</div>
	);
}

export default Sidebar;
