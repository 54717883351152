import React from 'react';

function Footer() {

	return (
		<footer className="footer-container">
			<div className="footer-logo">
				<img src="/logosafite2.png" alt="" width={130} />
			</div>
			<div className="footer-copyright">
				&copy; 2024 Safite. Todos los derechos reservados.
			</div>
		</footer>
	);
}

export default Footer;
