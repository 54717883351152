import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import Title from '../../components/globales/Title';
import EncuestasTabla from './EncuestasTabla';
import Loading from '../../components/globales/Loading';

const  Encuestas= () => {
	const [encuestasInicial, setEncuestasInicial] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {

		axios.get('/encuesta/listar')
			.then(response => {
				setEncuestasInicial(response.data.data);
				setLoading(false);				
			})
			.catch(error => {
				setError(error);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <Loading mensaje={'Cargando Encuestas...'} />
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<EncuestasTabla encuestasInicial={encuestasInicial} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Encuestas;
