import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import { useAuth } from '../../auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/globales/Loading';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        error: '',
        errorEmail: '',
        errorContrasena: ''
    });
    const [loading, setLoading] = useState(false); // Nuevo estado para el spinner

    const navigate = useNavigate();
    const { isLoggedIn, login } = useAuth();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            error: '',
            errorEmail: '',
            errorContrasena: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Mostrar el spinner

        const { email, password } = formData;

        try {
            const response = await axios.post('/loginReact', { email, password });

            if (response.data.token) {
                login(response.data.token, response.data.user);
                navigate('/');
            } else if (response.data.errors) {
                setFormData(prevState => ({
                    ...prevState,
                    errorEmail: response.data.errors.email || '',
                    errorContrasena: response.data.errors.password || ''
                }));
            }
        } catch (err) {
            setFormData(prevState => ({
                ...prevState,
                error: 'Credenciales inválidas. Intenta de nuevo.'
            }));
        } finally {
            setLoading(false); // Ocultar el spinner
        }
    };

    return (
        <div className="containerLogin">
            {loading ? (
                <Loading mensaje="Iniciando sesión..." /> // Mostrar spinner
            ) : (
                <form onSubmit={handleSubmit} className="formLogin">
                    <center>
                        <img src="logosafite2.png" alt="" width="75%" />
                        <h4 className="titleLogin">Inspección Preoperacional</h4>
                    </center>
                    <div className="inputGroupLogin">
                        <label htmlFor="email" className="labelLogin">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="inputLogin"
                            required
                        />
                    </div>
                    {formData.errorEmail && <div className="alert alert-danger">{formData.errorEmail}</div>}
                    <div className="inputGroupLogin">
                        <label htmlFor="password" className="labelLogin">Contraseña:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="inputLogin"
                            required
                        />
                    </div>
                    {formData.errorContrasena && <div className="alert alert-danger">{formData.errorContrasena}</div>}
                    {formData.error && <div className="alert alert-danger">{formData.error}</div>}
                    <button type="submit" className="buttonLogin">Iniciar Sesión</button>
                </form>
            )}
        </div>
    );
};

export default Login;
