import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import Title from '../../components/globales/Title';
import TiposDatoTabla from './TiposDatoTabla';
import Loading from '../../components/globales/Loading';
import Footer from '../../components/globales/Footer';

function TiposDato() {
	const [tiposDatoInicial, setTiposDatoInicial] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		axios.get('/encuestaTipoDato/listar')
			.then(response => {
				setTiposDatoInicial(response.data.data);
				setLoading(false);
			})
			.catch(error => {
				setError(error);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div><Loading mensaje={'Cargando Tipos de Datos...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<TiposDatoTabla tiposDatoInicial={tiposDatoInicial} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default TiposDato;
