import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AuthProvider, useAuth } from './auth/context/AuthContext';


import Navbar from './components/globales/NavBar';

import TiposPregunta from './pages/tipospregunta/TiposPregunta';
import Temas from './pages/temas/Temas';
import TiposDato from './pages/tiposdato/TiposDato';

import EncuestaConductor from './pages/conductor/EncuestaConductor';
import Login from './pages/login/Login';

import Empresas from './pages/empresas/Empresas';
import Usuarios from './pages/usuarios/Usuarios';
import Respuestas from './pages/encuestas/preguntas/respuestas/Respuestas';


import Encuestas from './pages/encuestas/Encuestas';

import PreguntasTabla from './pages/encuestas/preguntas/PreguntasTabla';
import RespuestasTabla from './pages/encuestas/preguntas/respuestas/RespuestasTabla';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import { EmpresasCodigo } from './pages/empresas/codigo/EmpresasCodigo';
import Host from './pages/host/Host';
// Importa los otros componentes que necesites...

function App() {
	const [showNav, setShowNav] = useState(true);

	return (
		<AuthProvider>
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/encuesta-conductor/:token" element={<EncuestaConductor />} />
					<Route path="/*" element={<ProtectedLayout showNav={showNav} setShowNav={setShowNav} />} />
				</Routes>
			</Router>
		</AuthProvider>
	);
}

const ProtectedLayout = ({ showNav, setShowNav }) => {
	const { isLoggedIn } = useAuth();

	if (!isLoggedIn) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			<header>
				<GiHamburgerMenu onClick={() => setShowNav(!showNav)} />
			</header>
			<Navbar show={showNav} />
			<div className={`main ${showNav ? '' : 'full-width'}`}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/empresas" element={<Empresas />} />
					<Route path="/host" element={<Host />} />
					<Route path="/empresas-codigo/:id" element={<EmpresasCodigo />} />
					<Route path="/usuarios" element={<Usuarios />} />
					<Route path="/encuestas" element={<Encuestas />} />
					<Route path="/preguntas/:id" element={<PreguntasTabla />} />
					<Route path="/respuestas/:id" element={<RespuestasTabla />} />
					<Route path="/temas" element={<Temas />} />
					<Route path="/tipo-pregunta" element={<TiposPregunta />} />
					<Route path="/tipo-dato" element={<TiposDato />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>
		</>
	);
};

export default App;
