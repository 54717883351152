import React, { useState, useEffect } from 'react';
import axios from '../../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/globales/Loading';
import Title from '../../../components/globales/Title';

import { Button, Table, Modal, Form, Pagination, } from 'react-bootstrap';
import Swal from 'sweetalert2';

const PreguntasTabla = () => {
	const { id } = useParams();
	const [errors, setErrors] = useState({});

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [enunciado, setEnunciado] = useState('');
	const [proposito, setProposito] = useState('');
	const [numeroRespuestas, setNumeroRespuestas] = useState('');
	const [porcentaje, setPorcentaje] = useState(0);
	const [tipoPregunta, setTipoPregunta] = useState([]);
    const [tipoPreguntaSeleccionado, setTipoPreguntaSeleccionado] = useState('');
	const [tema, setTema] = useState([]);
    const [temaSeleccionado, setTemaSeleccionado] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [preguntas, setPreguntas] = useState([]);
	const [accion, setAccion] = useState('crear');
	const [idPregunta, setIdPregunta] = useState('');

	const navigate = useNavigate();

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/encuesta/editar/${id}`);
				const { temas, tipoPregunta, preguntas } = response.data;
				
				setLoading(false);
				setTema(temas);
				setTipoPregunta(tipoPregunta);
				setPreguntas(preguntas);
			} catch (error) {
				setLoading(false);
				console.error(error);
			}
		};

		fetchData();
	}, [id]);

	if (loading) {
		return <div><Loading mensaje={'Cargando Preguntas...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	const enviarPregunta = (e) => {
		e.preventDefault();

		const pregunta = {
			id: idPregunta,
			enunciado,			
			proposito,
			numeroRespuestas,
			idEncuesta: id,
			tipoPregunta: tipoPreguntaSeleccionado,
			tema: temaSeleccionado,
			porcentaje: porcentaje ? porcentaje : 0,
			descripcion,
		};

		let formErrors = {};

		if (!enunciado) {
			formErrors.enunciado = 'El enunciado es obligatorio';
		}
		
		if (!tipoPreguntaSeleccionado) {
			formErrors.tipoPregunta = 'Seleccione que tipo de pregunta es';
		}

		if (!temaSeleccionado) {
			formErrors.tema = 'Seleccione el tema';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				
				axios.post('/encuestaPregunta/crear', pregunta)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creada",
							text: "La pregunta ha sido creada con éxito",
							icon: "success"
						});

						setEnunciado('');
						setProposito('');
						setNumeroRespuestas('');
						setPorcentaje('');
						setTipoPregunta('');
						setTema('');
						setDescripcion('');
						setErrors([]);

						return axios.get(`/encuesta/editar/${id}`);
					})
					.then(response => {
						
						const { temas, tipoPregunta, preguntas } = response.data;
			
						setTema(temas);
						setTipoPregunta(tipoPregunta);
						setPreguntas(preguntas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {
				
				axios.put(`/encuestaPregunta/actualizar/${idPregunta}`, pregunta)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Actualizada",
							text: "La pregunta sido actualizada con éxito",
							icon: "success"
						});

						setEnunciado('');
						setErrors([]);

						return axios.get(`/encuesta/editar/${id}`);
					})
					.then(response => {
						const { temas, tipoPregunta, preguntas } = response.data;
			
						setTema(temas);
						setTipoPregunta(tipoPregunta);
						setPreguntas(preguntas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const abrirModal = (accion, pregunta = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {
			
			setIdPregunta(pregunta.id);
			setEnunciado(pregunta.enunciado);
			setProposito(pregunta.proposito);
			setNumeroRespuestas(pregunta.numero_opciones_respuesta);
			setTipoPreguntaSeleccionado(pregunta.id_tipo_pregunta);
			setTemaSeleccionado(pregunta.id_tema);
			setPorcentaje(pregunta.porcentaje);
			setDescripcion(pregunta.descripcion);

		} else {

			setEnunciado('');
			setProposito('');
			setNumeroRespuestas('');
			setTipoPreguntaSeleccionado('');
			setTemaSeleccionado('');
			setPorcentaje('');
			setDescripcion('');
		}
		handleShow();
	};

	const eliminarPregunta = (id) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar esta pregunta?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/encuestaPregunta/borrar/${id}`)
					.then(response => {
						Swal.fire({
							title: "Borrada",
							text: "La pregunta ha sido eliminada con éxito",
							icon: "success"
						}).then(() => {
							setPreguntas(preguntas.filter(pregunta => pregunta.id !== id));
						});
					})
					.catch(error => {
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al intentar eliminar la pregunta.",
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = preguntas.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Preguntas'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th width="100px;" style={{ textAlign: 'center' }}>No</th>
								<th width="40%;">Enunciado</th>
								<th width="20%;">Proposito</th>
								<th width="10%;" style={{ textAlign: 'center' }}>Opcion (Respuesta)</th>
								<th width="10%;">Porcentaje (Encuesta)</th>
								<th width="10%;" style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
										<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.length > 0 ? (
								currentItems.map((pregunta, index) => (
									<tr key={index}>
										<td style={{ textAlign: 'center' }}>{indexOfFirstItem + index + 1}</td>
										<td>{pregunta.enunciado}</td>
										<td>{pregunta.proposito}</td>
										<td style={{ textAlign: 'center' }}>{pregunta.numero_opciones_respuesta}</td>
										<td>{pregunta.porcentaje}</td>
										<td style={{ textAlign: 'center' }}>
											<div className="btn-group" role="group">
												<Button variant="outline-info" title="Agregar Respuestas" onClick={() => navigate(`/respuestas/${pregunta.id}`)}>
													<span className="material-icons">note_add</span>
												</Button>
												<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', pregunta)}>
													<span className="material-icons">edit</span>											
												</Button>
												<Button variant="outline-danger" title="Borrar" onClick={() => eliminarPregunta(pregunta.id)} disabled={pregunta.registros_respuestas > 0}>
													<span className="material-icons">delete</span>
												</Button>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="7" style={{ textAlign: 'center' }}>No hay preguntas</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(preguntas.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Pregunta'}
						{accion === 'editar' && 'Editar Pregunta'}
						{accion === 'consultar' && 'Consultar Pregunta'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Enunciado</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="enunciado"
								placeholder="Enunciado"
								value={enunciado}
								onChange={(e) => setEnunciado(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.enunciado &&
								<div className="alert alert-danger">
									{errors.enunciado}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Proposito</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="propoosito"
								placeholder="Proposito"
								value={proposito}
								onChange={(e) => setProposito(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.proposito &&
								<div className="alert alert-danger">
									{errors.proposito}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>No Respuestas</Form.Label>
							<Form.Control
								type="number"
								className="form-control"
								name="numeroRespuestas"
								placeholder="Numero Respuestas"
								value={numeroRespuestas}
								onChange={(e) => setNumeroRespuestas(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.numeroRespuestas &&
								<div className="alert alert-danger">
									{errors.numeroRespuestas}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label><strong>Tipo Pregunta:</strong></Form.Label>
							<Form.Select
								name="tipoPregunta"
								required
								value={tipoPreguntaSeleccionado}
								onChange={(e) => setTipoPreguntaSeleccionado(e.target.value)}
							>
								<option value="">Seleccione tipo de pregunta...</option>
								{Array.isArray(tipoPregunta) && tipoPregunta.length > 0 && tipoPregunta.map((tipo) => (
									<option key={tipo.id} value={tipo.id}>
										{tipo.nombre}
									</option>
								))}
							</Form.Select>							
							{errors.tipoPregunta &&
								<div className="alert alert-danger">
									{errors.tipoPregunta}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label><strong>Tema:</strong></Form.Label>
							<Form.Select
								name="tema"
								required
								value={temaSeleccionado}
								onChange={(e) => setTemaSeleccionado(e.target.value)}
							>
								<option value="">Seleccione tema...</option>
								{Array.isArray(tema) && tema.map((tipo) => (
									<option key={tipo.id} value={tipo.id}>
										{tipo.nombre}
									</option>
								))}
							</Form.Select>							
							{errors.tema &&
								<div className="alert alert-danger">
									{errors.tema}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Porcentaje Acierto</Form.Label>
							<Form.Control
								type="number"
								className="form-control"
								name="porcentaje"
								placeholder="Porcentaje Acierto"
								value={porcentaje}
								onChange={(e) => setPorcentaje(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.porcentaje &&
								<div className="alert alert-danger">
									{errors.porcentaje}
								</div>
							}
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Descripción</Form.Label>
							<Form.Control
								className="form-control"
								name="descripcion"
								placeholder="Descripcion"
								value={descripcion}
								onChange={(e) => setDescripcion(e.target.value)}
								as="textarea" rows={3}
								disabled={accion === 'consultar'}
							/>
							{errors.descripcion &&
								<div className="alert alert-danger">
									{errors.descripcion}
								</div>
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarPregunta}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PreguntasTabla;
