import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import TiposPreguntaTabla from './TiposPreguntaTabla';
import Loading from '../../components/globales/Loading';

function TiposPregunta() {
	const [tiposPreguntaInicial, setTiposPreguntaInicial] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		axios.get('/encuestaTipoPregunta/listar')
			.then(response => {
				setTiposPreguntaInicial(response.data.data);
				setLoading(false);
			})
			.catch(error => {
				setError(error);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div><Loading mensaje={'Cargando Tipos de Pregunta...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<TiposPreguntaTabla tiposPreguntaInicial={tiposPreguntaInicial} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default TiposPregunta;
