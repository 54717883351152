import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import Swal from 'sweetalert2';
import { Table, Button, Pagination, Modal, Form } from 'react-bootstrap';
import Title from '../../components/globales/Title';

function EmpresasTabla({ empresasInicial }) {
	const [empresas, setEmpresas] = useState(empresasInicial);
	const [nit, setNit] = useState('');
	const [nombre, setNombre] = useState('');
	const [celular, setCelular] = useState('');
	const [direccion, setDireccion] = useState('');
	const [host, setHost] = useState('');
	const [idHostSeleccionado, setIdHostSeleccionado] = useState('');
	const [errors, setErrors] = useState([]);
	const [accion, setAccion] = useState('crear');
	const [id, setId] = useState(null);

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	const navigate = useNavigate();

	useEffect(() => {
		axios.get('/host/listarActivos')
			.then(response => {

				setHost(response.data);
			})
			.catch(error => {
				setErrors(error);
			});
	}, []);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const enviarTema = (e) => {
		e.preventDefault();

		const empresa = {
			id,
			nit,
			nombre,
			celular,
			direccion,
			id_host: idHostSeleccionado
		};

		let formErrors = {};

		if (!nit) {
			formErrors.nit = 'El nit es obligatorio';
		}

		if (!nombre) {
			formErrors.nombre = 'El nombre de preguntas obligatorio';
		}

		if (!celular) {
			formErrors.celular = 'El teléfono es obligatorio';
		}

		if (!direccion) {
			formErrors.direccion = 'La dirección es obligatoria';
		}

		if (!host) {
			formErrors.id_host = 'Seleccione host';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				axios.post('/empresas/crear', empresa)				
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creada",
							text: "la empresa ha sido creada con éxito",
							icon: "success"
						});

						setNit('');
						setNombre('');
						setCelular('');
						setDireccion('');
						setIdHostSeleccionado('');
						setErrors([]);

						return axios.get('/empresas/listar');
					})
					.then(response => {
						const nuevasEmpresas = response.data.data;
						setEmpresas(nuevasEmpresas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {
				axios.put(`/empresas/actualizar/${id}`,  empresa)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Actualizada",
							text: "la empresa sido actualizada con éxito",
							icon: "success"
						});


						setNit('');
						setNombre('');
						setCelular('');
						setDireccion('');
						setIdHostSeleccionado('');
						setErrors([]);

						return axios.get('/empresas/listar');
					})
					.then(response => {
						const nuevasEmpresas = response.data.data;
						setEmpresas(nuevasEmpresas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const abrirModal = async (accion, empresa = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {

			const response = await axios.get(`/empresas/consultar/${empresa.codigo_empresa}`);

			setId(response.data.empresa.codigo_empresa);
			setNit(response.data.empresa.nit);
			setNombre(response.data.empresa.nombre_empresa);
			setCelular(response.data.empresa.celular);
			setDireccion(response.data.empresa.direccion);
			setIdHostSeleccionado(response.data.empresa.host_empresa);
		} else {
			setId(null);
			setNit('');
			setNombre('');
			setCelular('');
			setDireccion('');
			setIdHostSeleccionado('');
		}
		handleShow();
	};

	const eliminarEmpresa = (codigo_empresa) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar la empresa?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/empresas/borrar/${codigo_empresa}`)
					.then(response => {
						Swal.fire({
							title: "Borrada",
							text: "La empresa ha sido eliminada con éxito",
							icon: "success"
						}).then(() => {
							setEmpresas(empresas.filter(empresa => empresa.codigo_empresa !== codigo_empresa));
						});
					})
					.catch(error => {
						console.error('Error borrando la empresa:', error);
						Swal.fire({
							title: "Error",
							text: 'Ocurrió un error al intentar eliminar la empresa.',
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = empresas.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Empresas'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No</th>
								<th>Nit</th>
								<th>Nombre</th>
								<th>Celular</th>
								<th>Dirección</th>
								<th style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
											<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems && currentItems.length > 0 ? (
								currentItems.map((item, index) => (
								<tr key={item.codigo_empresa}>
									<td style={{ textAlign: 'center' }}>{index + 1}</td>
									<td>{item.nit}</td>
									<td>{item.nombre_empresa}</td>
									<td>{item.celular}</td>
									<td>{item.direccion}</td>
									<td style={{ textAlign: 'center' }}>
										<div className="btn-group" role="group">
											<Button variant="outline-info" title="Agreagr Código" onClick={() => navigate(`/empresas-codigo/${item.codigo_empresa}`)}>
												<span className="material-icons">note_add</span>
											</Button>
											<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', item)}>
												<span className="material-icons">edit</span>											
											</Button>
											<Button variant="outline-danger" title="Borrar" onClick={() => eliminarEmpresa(item.codigo_empresa)} disabled={item.registros_encuestas > 0}>
												<span className="material-icons">delete</span>
											</Button>
										</div>
									</td>
								</tr>
								))
							) : (
								<tr>
									<td colSpan="6" style={{ textAlign: 'center' }}>
										No se ha encontrado información.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(empresas.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Empresa'}
						{accion === 'editar' && 'Editar Empresa'}
						{accion === 'consultar' && 'Consultar Empresa'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Nit</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="nit"
								placeholder="Nit"
								value={nit}
								onChange={(e) => setNit(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.nit &&
								<div className="alert alert-danger">
									{errors.nit}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="nombre"
								placeholder="Nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.nombre &&
								<div className="alert alert-danger">
									{errors.nombre}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Celular</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="celular"
								placeholder="Celular"
								value={celular}
								onChange={(e) => setCelular(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.celular &&
								<div className="alert alert-danger">
									{errors.celular}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Dirección</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="direccion"
								placeholder="Dirección"
								value={direccion}
								onChange={(e) => setDireccion(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.direccion &&
								<div className="alert alert-danger">
									{errors.direccion}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label><strong>Host:</strong></Form.Label>
							<Form.Select
								name="id_host"
								required
								value={idHostSeleccionado}
								onChange={(e) => setIdHostSeleccionado(e.target.value)}
							>
								<option value="">Seleccione host...</option>
								{Array.isArray(host) && host.length > 0 && host.map((host) => (
									<option key={host.codigo} value={host.codigo}>
										{host.descripcion}
									</option>
								))}
							</Form.Select>							
							{errors.id_host &&
								<div className="alert alert-danger">
									{errors.id_host}
								</div>
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarTema}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default EmpresasTabla;
