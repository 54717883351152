import React, { useState } from 'react';
import axios from '../../axiosConfig';
import Swal from 'sweetalert2';
import { Pagination, Table, Button, Modal, Form } from 'react-bootstrap';
import Title from '../../components/globales/Title';

function UsuariosTabla({ usuariosInicial }) {
	const [usuarios, setUsuarios] = useState(usuariosInicial);
	const [documento, setDocumento] = useState('');
	const [nombre, setNombre] = useState('');
	const [apellido, setApellido] = useState('');
	const [telefono, setTelefono] = useState('');
	const [correo, setCorreo] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState([]);
	const [accion, setAccion] = useState('crear');
	const [id, setId] = useState(null);

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const enviarUsuario = (e) => {
		e.preventDefault();

		const usuario = {
			id,
			document_identification: documento,
			name: nombre,
			last_name: apellido,
			cell_phone: telefono,
			email: correo,
			password,
		};

		let formErrors = {};

		if (!documento) {
			formErrors.document_identification = 'El documento es obligatorio';
		}

		if (!nombre) {
			formErrors.name = 'El nombre es obligatorio';
		}

		if (!telefono) {
			formErrors.cell_phone = 'El teléfono es obligatorio';
		}

		if (!correo) {
			formErrors.email = 'El correo es obligatorio';
		}

		if (!password) {
			formErrors.password = 'La contraseña es obligatoria';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				axios.post('/register', usuario)
					.then(response => {
						handleClose();

						Swal.fire({
							title: "Creado",
							text: "El usuario ha sido creado con éxito",
							icon: "success"
						});

						setDocumento('');
						setNombre('');
						setApellido('');
						setTelefono('');
						setCorreo('');
						setPassword('');
						setErrors([]);

						return axios.get('/usuarios/listar');
					})
					.then(response => {
						const nuevosUsuarios = response.data.users.data;
						setUsuarios(nuevosUsuarios);
					})
					.catch(error => {
						if (error.response && error.response.status === 400) {
							const validationErrors = error.response.data.errors;
							setErrors(validationErrors);
						} else {
							setErrors("Ocurrió un error inesperado.");
						}
					});
			} else if (accion === 'editar') {
				const usuarioActualizado = { ...usuario };
				if (!password) {
					delete usuarioActualizado.password;
				}
			
				axios.put(`/usuarios/actualizar/${id}`, usuarioActualizado)
					.then(response => {
						handleClose();
			
						Swal.fire({
							title: "Actualizado",
							text: "El usuario ha sido actualizado con éxito",
							icon: "success"
						});
			
						setDocumento('');
						setNombre('');
						setApellido('');
						setTelefono('');
						setCorreo('');
						setPassword('');
						setErrors([]);
			
						return axios.get('/usuarios/listar');
					})
					.then(response => {
						const nuevosUsuarios = response.data.users.data;
						setUsuarios(nuevosUsuarios);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}

		
	};

	const abrirModal = (accion, usuario = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {
			
			setId(usuario.codigo_usuario);
			setDocumento(usuario.documento_identificacion);
			setNombre(usuario.nombre);
			setApellido(usuario.apellidos);
			setTelefono(usuario.celular);
			setCorreo(usuario.correo_electronico);
			setPassword('');
		} else {
			setDocumento('');
			setNombre('');
			setApellido('');
			setTelefono('');
			setCorreo('');
			setPassword('');
			setId(null);
		}
		handleShow();
	};

	const eliminarUsuario = (id) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar este usuario?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/usuarios/borrar/${id}`)
					.then(response => {
						Swal.fire({
							title: "Borrado",
							text: "el usuario ha sido eliminado con éxito",
							icon: "success"
						}).then(() => {
							setUsuarios(usuarios.filter(usuario => usuario.codigo_usuario !== id));
						});
					})
					.catch(error => {
						console.error('Error borrando el usuario:', error);
						Swal.fire({
							title: "Error",
							text: 'Ocurrió un error al intentar eliminar el usuario.',
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = usuarios.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Usuarios'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No</th>
								<th style={{ width: '200px' }}>Documento</th>
								<th style={{ width: '200px' }}>Nombre</th>
								<th style={{ width: '200px' }}>Apellido</th>
								<th style={{ width: '200px' }}>Teléfono</th>
								<th style={{ width: '200px' }}>Email</th>
								<th style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
										<span className="material-icons">note_add</span>
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems && currentItems.length > 0 ? (
								currentItems.map((item, index) => (
									<tr key={item.codigo_usuario}>
										<td style={{ textAlign: 'center' }}>{index + 1}</td>
										<td style={{ width: '200px' }}>{item.documento_identificacion}</td>
										<td style={{ width: '200px' }}>{item.nombre}</td>
										<td style={{ width: '200px' }}>{item.apellidos}</td>
										<td style={{ width: '200px' }}>{item.celular}</td>
										<td style={{ width: '200px' }}>{item.correo_electronico}</td>
										<td style={{ textAlign: 'center' }}>
											<div className="btn-group" role="group">
												<Button variant="outline-info" title="Consultar" onClick={() => abrirModal('consultar', item)}>
													<span className="material-icons">search</span>
												</Button>
												<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', item)}>
													<span className="material-icons">edit</span>
												</Button>
												<Button variant="outline-danger" title="Borrar" onClick={() => eliminarUsuario(item.codigo_usuario)} disabled={item.registros_encuestas > 0}>
													<span className="material-icons">delete</span>
												</Button>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="7" style={{ textAlign: 'center' }}>
										No se ha encontrado información.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(usuarios.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Usuario'}
						{accion === 'editar' && 'Editar Usuario'}
						{accion === 'consultar' && 'Consultar Usuario'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="formDocumento">
							<Form.Label>Documento</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="documento"
								placeholder="Documento"
								value={documento}
								onChange={(e) => setDocumento(e.target.value)}
								disabled={accion === 'consultar'}
								required
							/>
							{errors.document_identification &&
								<div className="alert alert-danger">
									{errors.document_identification}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="formNombre">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="nombre"
								placeholder="Nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.name &&
								<div className="alert alert-danger">
									{errors.name}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="formApellido">
							<Form.Label>Apellido</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="apellido"
								placeholder="Apellido"
								value={apellido}
								onChange={(e) => setApellido(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.last_name &&
								<div className="alert alert-danger">
									{errors.last_name}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="formTelefono">
							<Form.Label>Teléfono</Form.Label>
							<Form.Control
								type="number"
								className="form-control"
								name="telefono"
								placeholder="Telefono"
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.cell_phone &&
								<div className="alert alert-danger">
									{errors.cell_phone}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="formCorreo">
							<Form.Label>Correo</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="correo"
								placeholder="Correo"
								value={correo}
								onChange={(e) => setCorreo(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.email &&
								<div className="alert alert-danger">
									{errors.email}
								</div>
							}
						</Form.Group>
						{(accion === 'crear' || accion === 'editar') && (
							<Form.Group className="mb-3" controlId="formPassword">
								<Form.Label>Contraseña</Form.Label>
								<Form.Control
									type="password"
									className="form-control"
									name="password"
									placeholder="Contraseña"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required={accion === 'crear'}
								/>
								{errors.password &&
									<div className="alert alert-danger">
										{errors.password}
									</div>
								}
							</Form.Group>
						)}
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarUsuario}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default UsuariosTabla;