import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import Loading from '../../components/globales/Loading';
import { Table, Button, Modal, Form, Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Title from '../../components/globales/Title';

const Host = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [errors, setErrors] = useState([]);

	const [host, setHost] = useState([]);
	const [accion, setAccion] = useState('crear');

	const [codigo, setCodigo] = useState('');
	const [url, setUrl] = useState('second');
	const [estado, setEstado] = useState('');
	const [descripcion, setDescripcion] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/host/listar');

				setHost(response.data.data);
				
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError(error);
			}
		};

		fetchData();
	}, []);

	if (loading) {
		return <div><Loading mensaje={'Cargando Host...'} /></div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	const enviarHost = (e) => {
		e.preventDefault();

		const host = {
			url,			
			estado,
			descripcion
		};

		let formErrors = {};

		if (!url) {
			formErrors.url = 'La url es obligatoria';
		}
		
		if (!estado) {
			formErrors.estado = 'El estado es obligatorio';
		}

		if (!descripcion) {
			formErrors.descripcion = 'La descripción es obligatoria';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				
				axios.post('/host/crear', host)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creado",
							text: response.data.message,
							icon: "success"
						});

						setUrl('');
						setEstado('');
						setDescripcion('');
						setErrors([]);

						return axios.get('/host/listar');
					})
					.then(response => {

						setHost(response.data.data);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {			
				axios.put(`/host/actualizar/${codigo}`, host)
					.then(response => {
						handleClose();

						Swal.fire({
							title: "Actualizado",
							text: response.data.message,
							icon: "success"
						});

						setUrl('');
						setEstado('');
						setDescripcion('');
						setErrors([]);

						return axios.get('/host/listar');
					})
					.then(response => {

						setHost(response.data.data);

					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const eliminarHost = (codigo) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar este host?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/host/borrar/${codigo}`)
					.then(response => {
						Swal.fire({
							title: "Borrado",
							text: response.data.message,
							icon: "success"
						}).then(() => {
							setHost(host.filter(host => host.codigo !== codigo));
						});
					})
					.catch(error => {
						console.error('Error borrando el host:', error);
						Swal.fire({
							title: "Error",
							text: "Host no encontrado.",
							icon: "error"
						});
					});
			}
		});
	};

	const abrirModal = async (accion, host = {}) => {
		setAccion(accion);
		if (accion === 'editar') {
			const response = await axios.get(`/host/consultar/${host.codigo}`);
			
			setCodigo(host.codigo);
			setUrl(response.data.empresa.url);
			setEstado(response.data.empresa.estado);
			setDescripcion(response.data.empresa.descripcion);
			
		} else {
			setUrl('');
			setEstado('');
			setDescripcion('');

		}
		handleShow();
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = host.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Host'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No</th>
								<th width="40%;">Url</th>
								<th width="20%;">Estado</th>
								<th width="10%;">Descripción</th>
								<th width="10%;" style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
											<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.length > 0 ? (
								currentItems.map((host, index) => (
									<tr key={index}>
									<td style={{ textAlign: 'center' }}>{indexOfFirstItem + index + 1}</td>
									<td>{host.url}</td>
									<td>
										{host.estado === 1 ? 'Activo' : 'Inactivo'}
									</td>
									<td>{host.descripcion}</td>
									<td style={{ textAlign: 'center' }}>
										<div className="btn-group" role="group">
											<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', host)}>
												<span className="material-icons">edit</span>											
											</Button>
											<Button variant="outline-danger" title="Borrar" onClick={() => eliminarHost(host.codigo)} disabled={host.registros_encuestas > 0}>
												<span className="material-icons">delete</span>
											</Button>
										</div>
									</td>
									</tr>
								))
								) : (
								<tr>
									<td colSpan="5" style={{ textAlign: 'center' }}>No hay host</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(host.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Host'}
						{accion === 'editar' && 'Editar Host'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="urlInput">
							<Form.Label>URL</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="url"
								placeholder="URL"
								value={url}
								onChange={(e) => setUrl(e.target.value)}
								required
							/>
							{errors.url && <div className="alert alert-danger">{errors.url}</div>}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label><strong>Estado</strong></Form.Label>
							<Form.Select
								name="estado"
								required
								value={estado}
								onChange={(e) => setEstado(e.target.value)}
							>
								<option value="">Seleccione estado...</option>
								<option value={1}>Activo</option>
								<option value={2}>Inactivo</option>
							</Form.Select>							
							{errors.estado &&
								<div className="alert alert-danger">
									{errors.estado}
								</div>
							}
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Descripcion</Form.Label>
							<Form.Control
								className="form-control"
								name="descripcion"
								placeholder="Descripción"
								value={descripcion}
								onChange={(e) => setDescripcion(e.target.value)}
								as="textarea" rows={3}
								disabled={accion === 'consultar'}
							/>
							{errors.descripcion &&
								<div className="alert alert-danger">
									{errors.descripcion}
								</div>
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					<Button variant="primary" onClick={enviarHost}>
						{accion === 'crear' ? 'Guardar' : 'Actualizar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Host;
