import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import { Table, Button, Modal, Pagination, Form } from 'react-bootstrap';
import Title from '../../components/globales/Title';
import Swal from 'sweetalert2';

function EncuestasTabla({ encuestasInicial }) {
	const [encuestas, setEncuestas] = useState(encuestasInicial);
	const [nombre, setNombre] = useState('');
	const [objetivo, setObjetivo] = useState('');
	const [numeroPreguntas, setNumeroPreguntas] = useState('');
	const [tiempoDesarrollo, setTiempoDesarrollo] = useState('');
	const [empresas, setEmpresas] = useState('');
	const [idEmpresaSeleccionada, setIdEmpresaSeleccionada] = useState('')
	const [accion, setAccion] = useState('crear');
	const [errors, setErrors] = useState([]);
	const [id, setId] = useState(null);

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	const navigate = useNavigate();

	useEffect(() => {
		axios.get('/empresas/listarActivos')
			.then(response => {

				setEmpresas(response.data);
			})
			.catch(error => {
				setErrors(error);
			});
	}, []);

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const enviarEncuesta = (e) => {
		e.preventDefault();

		const encuesta = {
			id,
			nombre,
			objetivo,
			numero_preguntas: numeroPreguntas,
			tiempo_desarrollo: tiempoDesarrollo,
			id_empresa: idEmpresaSeleccionada
		};

		let formErrors = {};

		if (!nombre) {
			formErrors.nombre = 'El nombre es obligatorio';
		}

		if (!numeroPreguntas) {
			formErrors.numero_preguntas = 'El número de preguntas obligatorio';
		}

		if (!tiempoDesarrollo) {
			formErrors.tiempo_desarrollo = 'El tiempo de desarrollo es obligatorio';
		}

		if (!objetivo) {
			formErrors.objetivo = 'La descripción es obligatoria';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				axios.post(`/encuesta/crear`, encuesta)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creada",
							text: "La encuesta ha sido creada con éxito",
							icon: "success"
						});

						setNombre('');
						setNumeroPreguntas('');
						setTiempoDesarrollo('');
						setIdEmpresaSeleccionada('');
						setObjetivo('');
						setErrors([]);

						return axios.get('/encuesta/listar');
					})
					.then(response => {
						const nuevasEncuestas = response.data.data;
						setEncuestas(nuevasEncuestas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {
				axios.put(`/encuesta/actualizar/${id}`,  encuesta)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Actualizada",
							text: "La encuesta ha sido actualizada con éxito",
							icon: "success"
						});

						setNombre('');
						setNumeroPreguntas('');
						setTiempoDesarrollo('');
						setIdEmpresaSeleccionada('');
						setObjetivo('');
						setErrors([]);

						return axios.get('/encuesta/listar');
					})
					.then(response => {
						const nuevasEncuestas = response.data.data;
						setEncuestas(nuevasEncuestas);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const abrirModal = (accion, encuesta = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {
			setId(encuesta.id);
			setNombre(encuesta.nombre);
			setObjetivo(encuesta.objetivo);
			setNumeroPreguntas(encuesta.numero_preguntas);
			setTiempoDesarrollo(encuesta.tiempo_desarrollo);
			setIdEmpresaSeleccionada(encuesta.id_empresa);
		} else {
			setId(null);
			setNombre('');
			setObjetivo('');
			setNumeroPreguntas('');
			setTiempoDesarrollo('');
			setIdEmpresaSeleccionada('');
		}
		handleShow();
	};

	const eliminarEncuesta = (id) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar la encuesta?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/encuesta/borrar/${id}`)
					.then(response => {
						Swal.fire({
							title: "Borrada",
							text: "la encuesta ha sido eliminado con éxito",
							icon: "success"
						}).then(() => {
							setEncuestas(encuestas.filter(encuesta => encuesta.id !== id));
						});
					})
					.catch(error => {
						console.error('Error borrando el tema:', error);
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al intentar eliminar la encuesta.",
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = encuestas.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Encuestas'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No</th>
								<th>Nombre</th>
								<th>Objetivo</th>
								<th>Empresa</th>
								<th style={{ textAlign: 'center' }}>Nro.Preguntas</th>
								<th style={{ textAlign: 'center' }}>Tiempo</th>
								<th style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
											<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems && currentItems.length > 0 ? (
								currentItems.map((item, index) => (
								<tr key={item.id}>
									<td style={{ textAlign: 'center' }}>{index + 1}</td>
									<td>{item.nombre}</td>
									<td>{item.objetivo}</td>
									<td>{item.nombre_empresa}</td>
									<td style={{ textAlign: 'center' }}>
										{item.numero_preguntas}
									</td>
									<td style={{ textAlign: 'center' }}>
										{item.tiempo_desarrollo}
									</td>
									<td style={{ textAlign: 'center' }}>
										<div className="btn-group" role="group">
											<Button variant="outline-info" title="Agregar Preguntas" onClick={() => navigate(`/preguntas/${item.id}`)}>
												<span className="material-icons">note_add</span>
											</Button>
											<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', item)}>
												<span className="material-icons">edit</span>											
											</Button>
											<Button variant="outline-danger" title="Borrar" onClick={() => eliminarEncuesta(item.id)} disabled={item.registros_preguntas > 0}>
												<span className="material-icons">delete</span>
											</Button>
										</div>
									</td>
								</tr>
								))
							) : (
								<tr>
									<td colSpan="7" style={{ textAlign: 'center' }}>
										No se ha encontrado información.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(encuestas.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Encuesta'}
						{accion === 'editar' && 'Editar Encuesta'}
						{accion === 'consultar' && 'Consultar Encuesta'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type="text"
								className="form-control"
								name="nombre"
								placeholder="Nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>						
							{errors.nombre &&
								<div className="alert alert-danger">
									{errors.nombre}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>No Preguntas</Form.Label>
							<Form.Control
								type="number"
								className="form-control"
								name="numeroPreguntas"
								placeholder="No preguntas"
								value={numeroPreguntas}
								onChange={(e) => setNumeroPreguntas(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.numero_preguntas &&
								<div className="alert alert-danger">
									{errors.numero_preguntas}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Tiempo de Desarrollo</Form.Label>
							<Form.Control
								type="time"
								className="form-control"
								name="tiempoDesarrollo"
								placeholder="Tiempo de Desarrollo"
								value={tiempoDesarrollo}
								onChange={(e) => setTiempoDesarrollo(e.target.value)}
								required
								disabled={accion === 'consultar'}
							/>
							{errors.tiempo_desarrollo &&
								<div className="alert alert-danger">
									{errors.tiempo_desarrollo}
								</div>
							}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label><strong>Empresa:</strong></Form.Label>
							<Form.Select
								name="id_empresa"
								required
								value={idEmpresaSeleccionada}
								onChange={(e) => setIdEmpresaSeleccionada(e.target.value)}
							>
								<option value="">Seleccione empresa...</option>
								{Array.isArray(empresas) && empresas.length > 0 && empresas.map((empresa) => (
									<option key={empresa.codigo_empresa} value={empresa.codigo_empresa}>
										{empresa.nombre_empresa}
									</option>
								))}
							</Form.Select>							
							{errors.id_empresa &&
								<div className="alert alert-danger">
									{errors.id_empresa}
								</div>
							}
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Objetivo</Form.Label>
							<Form.Control
								className="form-control"
								name="objetivo"
								placeholder="Objetivo"
								value={objetivo}
								onChange={(e) => setObjetivo(e.target.value)}
								as="textarea" rows={3}
								disabled={accion === 'consultar'}
							/>
							{errors.objetivo &&
								<div className="alert alert-danger">
									{errors.objetivo}
								</div>
							}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarEncuesta}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default EncuestasTabla;
