import React, { useState } from 'react';
import axios from '../../axiosConfig';
import Swal from 'sweetalert2';
import { Pagination, Table, Button, Modal, Form } from 'react-bootstrap';
import Title from '../../components/globales/Title';

function TiposDatoTabla({ tiposDatoInicial }) {
	const [tiposDato, setTiposDato] = useState(tiposDatoInicial);
	const [nombre, setNombre] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [errors, setErrors] = useState([]);
	const [accion, setAccion] = useState('crear');
	const [id, setId] = useState(null);

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setErrors([]);
	};
	const handleShow = () => setShow(true);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const enviarTipoDato = (e) => {
		e.preventDefault();

		const tipoDato = {
			id,
			nombre,
			descripcion
		};

		let formErrors = {};

		if (!nombre) {
			formErrors.nombre = 'El nombre es obligatorio';
		}

		if (!descripcion) {
			formErrors.descripcion = 'La descripción es obligatoria';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {

			if (accion === 'crear') {
				axios.post(`/encuestaTipoDato/crear`, tipoDato)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Creado",
							text: "El tipo de dato ha sido creado con éxito",
							icon: "success"
						});

						setNombre('');
						setDescripcion('');
						setErrors([]);

						return axios.get('/encuestaTipoDato/listar');
					})
					.then(response => {
						const nuevosTiposDato = response.data.data;
						setTiposDato(nuevosTiposDato);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			} else if (accion === 'editar') {
				axios.put(`/encuestaTipoDato/actualizar/${id}`,  tipoDato)
					.then(response => {
						handleClose();
						
						Swal.fire({
							title: "Actualizado",
							text: "El tipo de dato ha sido actualizado con éxito",
							icon: "success"
						});

						setNombre('');
						setDescripcion('');
						setErrors([]);

						return axios.get('/encuestaTipoDato/listar');
					})
					.then(response => {
						const nuevosTiposDato = response.data.data;
						setTiposDato(nuevosTiposDato);
					})
					.catch(error => {
						if (error.response && error.response.data.errors) {
							setErrors(error.response.data.errors);
							console.error('Error en la petición:', error);
						}
					});
			}
		}
	};

	const abrirModal = (accion, tipoDato = {}) => {
		setAccion(accion);
		if (accion === 'editar' || accion === 'consultar') {
			setId(tipoDato.id);
			setNombre(tipoDato.nombre);
			setDescripcion(tipoDato.descripcion);
		} else {
			setNombre('');
			setDescripcion('');
			setId(null);
		}
		handleShow();
	};

	const eliminarTipoDato = (id) => {
		Swal.fire({
			title: '¿Estás seguro de que deseas eliminar este tipo de dato?',
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Eliminar"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`/encuestaTipoDato/borrar/${id}`)
					.then(response => {
						Swal.fire({
							title: "Borrado",
							text: "El tipo de dato ha sido eliminado con éxito",
							icon: "success"
						}).then(() => {
							setTiposDato(tiposDato.filter(tipo => tipo.id !== id));
						});
					})
					.catch(error => {
						console.error('Error borrando el tipo de dato:', error);
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al intentar eliminar el tipo de dato.",
							icon: "error"
						});
					});
			}
		});
	};

	// Manejo de la paginación
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = tiposDato.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className="table-responsive" style={{ marginTop: '70px' }}>
				<Title title={'Tipos de Dato'} />
				<div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
					<Table striped>
						<thead>
							<tr>
								<th>No</th>
								<th>Nombre</th>
								<th>Descripcion</th>
								<th style={{ textAlign: 'center' }}>
									<Button variant="outline-success" title="Crear" onClick={() => abrirModal('crear')}>
											<span className="material-icons">note_add</span>								
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{currentItems && currentItems.length > 0 ? (
								currentItems.map((item, index) => (
								<tr key={item.id}>
									<td style={{ textAlign: 'center' }}>{index + 1}</td>
									<td>{item.nombre}</td>
									<td>{item.descripcion}</td>
									<td style={{ textAlign: 'center' }}>
										<div className="btn-group" role="group">
											<Button variant="outline-info" title="Consultar" onClick={() => abrirModal('consultar', item)}>
												<span className="material-icons">search</span>
											</Button>
											<Button variant="outline-warning" title="Editar" onClick={() => abrirModal('editar', item)}>
												<span className="material-icons">edit</span>											
											</Button>
											<Button variant="outline-danger" title="Borrar" onClick={() => eliminarTipoDato(item.id)} disabled={item.registros_preguntas > 0}>
												<span className="material-icons">delete</span>
											</Button>
										</div>
									</td>
								</tr>
								))
							) : (
								<tr>
									<td colSpan="7" style={{ textAlign: 'center' }}>
										No se ha encontrado información.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				{/* Paginación */}
				<Pagination>
					{Array.from({ length: Math.ceil(tiposDato.length / itemsPerPage) }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => paginate(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{accion === 'crear' && 'Crear Tipo de Dato'}
						{accion === 'editar' && 'Editar Tipo de Dato'}
						{accion === 'consultar' && 'Consultar Tipo de Dato'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Nombre</Form.Label>
						<Form.Control
							type="text"
							className="form-control"
							name="nombre"
							placeholder="Nombre"
							value={nombre}
							onChange={(e) => setNombre(e.target.value)}
							required
							disabled={accion === 'consultar'}
						/>						
						{errors.nombre &&
							<div className="alert alert-danger">
								{errors.nombre}
							</div>
						}
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
						<Form.Label>Descripción</Form.Label>
						<Form.Control
							className="form-control"
							name="descripcion"
							placeholder="Descripción"
							value={descripcion}
							onChange={(e) => setDescripcion(e.target.value)}
							as="textarea" rows={3}
							disabled={accion === 'consultar'}
						/>
						{errors.descripcion &&
							<div className="alert alert-danger">
								{errors.descripcion}
							</div>
						}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
					{accion !== 'consultar' && (
						<Button variant="primary" onClick={enviarTipoDato}>
							{accion === 'crear' ? 'Guardar' : 'Actualizar'}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TiposDatoTabla;
