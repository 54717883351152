import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Question from './Question';
import Footer from '../../components/globales/Footer';
import Loading from '../../components/globales/Loading';
import API_BASE_URL from '../../apiConfig';
import { Card } from 'react-bootstrap';

function EncuestaConductor() {
	const { token } = useParams();
	const [manifiesto, setManifiesto] = useState();
	const [empresa, setEmpresa] = useState();
	const [nitEmpresa, setNitEmpresa] = useState();
	const [conductor, setConductor] = useState();
	const [nombreConductor, setNombreConductor] = useState();
	const [ruta, setRuta] = useState();
	const [vehiculo, setVehiculo] = useState();
	const [preguntas, setPreguntas] = useState([]);
	const [respuestas, setRespuestas] = useState([]);
	const [observacionesPreguntas, setObservacionesPreguntas] = useState([]);
	const [observaciones, setObservaciones] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [paginaActual, setPaginaActual] = useState(1);
	const [resultadoEnvio, setResultadoEnvio] = useState(null);
	const [mensaje, setMensaje] = useState('Cargando Preguntas...');
	const preguntasPorPagina = 15;

	useEffect(() => {
		async function fetchQuestions() {
			try {
				const response = await axios.get(`${API_BASE_URL}/preguntas/${token}`);

				setNitEmpresa(response.data.data.nit_empesa);
				setEmpresa(response.data.data.nombre_empresa);
				setManifiesto(response.data.data.manifiesto);
				setConductor(response.data.data.conductor);
				setNombreConductor(response.data.data.nombre_conductor);
				setRuta(response.data.data.ruta);
				setVehiculo(response.data.data.placa);

				const encuesta = response.data.data.preguntar;

				const preguntasAgrupadas = encuesta.reduce((acc, q) => {
					const opcionesOrdenadas = (q.data || [])
						.map(option => ({
							code: option.codigo,
							text: option.opcion,
							aplicaObservacion: option.aplica_observacion
						}))
						.sort((a, b) => a.text.localeCompare(b.text));

					const pregunta = {
						id: q.codigo,
						text: q.enunciado,
						options: opcionesOrdenadas,
						defaultOption: opcionesOrdenadas.length > 0 ? opcionesOrdenadas[0].code : null,
						codigo_tema: q.codigo_tema,
						nombre_tema: q.nombre_tema,
					};

					if (!acc[q.codigo_tema]) {
						acc[q.codigo_tema] = {
							nombre_tema: q.nombre_tema,
							preguntas: []
						};
					}

					acc[q.codigo_tema].preguntas.push(pregunta);
					return acc;
				}, {});


				const preguntasAplanadas = Object.values(preguntasAgrupadas).flatMap(grupo => {
					return [{ esTitulo: true, nombre_tema: grupo.nombre_tema }, ...grupo.preguntas];
				});	

				setPreguntas(preguntasAplanadas);
				const soloPreguntas = preguntasAplanadas.filter(p => !p.esTitulo);
				const defaultResponses = soloPreguntas.map(q => q.defaultOption || null);
				setRespuestas(defaultResponses);

				setObservacionesPreguntas(Array(soloPreguntas.length).fill(''));
	
			} catch (err) {
				console.error('Error al cargar las preguntas:', err);
				setResultadoEnvio({ success: false, message: err.response?.data?.message || err.message });
			} finally {
				setLoading(false);
			}
		}

		fetchQuestions();
	}, [token]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [paginaActual]);

	const totalPaginas = Math.ceil(preguntas.length / preguntasPorPagina);
	const indice = (paginaActual - 1) * preguntasPorPagina;
	const paginacion = preguntas.slice(indice, indice + preguntasPorPagina);

	const setAnswer = (index, response) => {
		const respuesta = [...respuestas];
		respuesta[index] = response;
		setRespuestas(respuesta);
	};	

	const setObservacion = (index, observation) => {
		const nuevasObservaciones = [...observacionesPreguntas];
		nuevasObservaciones[index] = observation;
		setObservacionesPreguntas(nuevasObservaciones);
	};

	const enviarRespuestas = async () => {
		setMensaje('Enviando Respuestas...');
		setLoading(true);
		const respuesta = {
			referencia: token,
			questions: preguntas
				.filter((question, index) => !question.esTitulo)
				.map((question, index) => ({
					code: question.id,
					response: [respuestas[index]],
					observaciones: observacionesPreguntas[index]
				})),
			observaciones: observaciones
		};

		try {
			const response = await axios.post(`${API_BASE_URL}/procesarPreguntas`, respuesta);
			setResultadoEnvio({ success: true, message: response.data.message });
		} catch (err) {
			console.error('Error al enviar respuestas:', err);
			setResultadoEnvio({ success: false, message: err.response?.data?.message || err.message });
			console.log(err.message);
			
		} finally {
			setLoading(false);
		}
	};

	const pagAnterior = () => {
		if (paginaActual > 1) {
			setPaginaActual(paginaActual - 1);
		}
	};

	const pagSiguiente = () => {
		if (paginaActual < totalPaginas) {
			setPaginaActual(paginaActual + 1);
		}
	};

	return (
		<>
			{loading ? (
				<div className='load'>
					<Loading mensaje={mensaje} />
				</div>
			) : (
				<div>
					{resultadoEnvio ? ( 
						<>
							<div className="header-container">
								<img src="/camion2.jpg" alt="Camión" className="header-image" />
								<div className="header-title">Inspección Preoperacional</div>
							</div>
							<div className="container content-container">
								{resultadoEnvio.success ? (
									<div className="alert alert-success">
										{resultadoEnvio.message || 'Respuestas enviadas exitosamente'}
									</div>
								) : (
									<div className="alert alert-danger">
										{resultadoEnvio.message || 'Error al enviar respuestas'}
									</div>
								)}
							</div>
						</>
					) : (
						<div>
							<div className="header-container">
								<img src="/camion2.jpg" alt="Camión" className="header-image" />
								<div className="header-title">Inspección Preoperacional</div>
							</div>
							<div className="container content-container">
								{error && <div className="alert alert-danger">{error}</div>}
								{preguntas.length > 0 && (
									<div>
										{paginaActual === 1 && (
											<>
												<Card className="shadow card-custom-bg">
													<Card.Body>
														<center>
															<h5 style={{ marginTop: '15px' }}><strong>Datos Empresa</strong></h5>
														</center>
														<ul className="list-group list-group-flush">
															<li className="list-group-item"><strong>Nit: </strong>{nitEmpresa}</li>
															<li className="list-group-item"><strong>Empresa: </strong>{empresa}</li>
														</ul>
														<hr/>
														<center>
															<h5 style={{ marginTop: '15px' }}><strong>Datos Viaje</strong></h5>
														</center>
														<ul className="list-group list-group-flush">
															<li className="list-group-item"><strong>Manifiesto: </strong>{manifiesto}<span style={{ marginLeft: '20px' }}><strong>Vehiculo: </strong>{vehiculo}</span></li>
															<li className="list-group-item"><strong>Conductor: </strong>{conductor} {nombreConductor}</li>
															<li className="list-group-item"><strong>Ruta: </strong>{ruta}</li>
														</ul>
													</Card.Body>
												</Card>
												<center>
													<h5 style={{ marginTop: '15px' }}><strong>Encuesta</strong></h5>
												</center>
											</>
										)}
										{paginacion.map((item, index) => {
											if (item.esTitulo) {
												return (
													<center key={`titulo-${index}`}>
														<h6><strong>{item.nombre_tema}</strong></h6>
													</center>
												);
											} else {
											
												const preguntaIndexGlobal = indice + index;
												const ajustePorTitulos = preguntas.slice(0, preguntaIndexGlobal).filter(p => p.esTitulo).length;
												const preguntaIndex = preguntaIndexGlobal - ajustePorTitulos;

												return (
													<div key={`pregunta-${item.id}`}>
														<Question
															question={item}
															answer={respuestas[preguntaIndex]}
															observation={observacionesPreguntas[preguntaIndex]}
															setAnswer={(response) => setAnswer(preguntaIndex, response)}
															setObservacion={(observation) => setObservacion(preguntaIndex, observation)}
														/>
													</div>
												);
											}
										})}
										{paginaActual === totalPaginas && (
											<div>
												<div className="form-floating">
													<textarea
														className="form-control"
														placeholder="Observaciones"
														id="observaciones"
														value={observaciones}
														onChange={(e) => setObservaciones(e.target.value)}
													></textarea>
													<label htmlFor="floatingTextarea">Observaciones Generales</label>
												</div>
											</div>
										)}
										{preguntas.length > preguntasPorPagina && (
											<div>
												<br />
												<div className="pagination-indicators">
													{Array.from({ length: totalPaginas }, (_, i) => (
														<span key={i} className={`dot ${paginaActual === i + 1 ? 'active' : ''}`}></span>
													))}
												</div>
												<div className="pagination-buttons d-flex justify-content-center mt-4">
													<button onClick={pagAnterior} className="btn btn-secondary mx-2" disabled={paginaActual === 1}>Anterior</button>
													<button onClick={pagSiguiente} className="btn btn-secondary mx-2" disabled={paginaActual === totalPaginas}>Siguiente</button>
												</div>
											</div>
										)}
										{paginaActual === totalPaginas && (
											<div className="submit-button text-center">
												<button onClick={enviarRespuestas} className="btn custom-submit-button mt-4">Registrar Respuestas</button>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					)}
					<Footer />
				</div>
			)}
		</>
	);
}

export default EncuestaConductor;
